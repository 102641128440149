<template>
    <v-card
    >
        <dan-messages-list :domain-name="inventoryItem.name"/>


        <conversations-card
                :domain-name="inventoryItem.name"
                :reload="true"
                class="px-0 mx-0"
                @collapse="$emit('collapse')"
        />
    </v-card>

</template>

<script>
import {mapGetters, mapActions} from "vuex";
import DanMessagesList from "@/components/extra/DanMessagesList";
import ConversationsCard from "@/components/leads/ConversationsCard.vue";

export default {
    components: {ConversationsCard, DanMessagesList},
    props: ["inventoryItem"],
    data: () => ({
        active: [],
        open: [],
    }),

    computed: {
        ...mapGetters({
            conversations: "conversations/getConversationsByDomainName",
        }),
    },
    methods: {
        ...mapActions("conversations", ["loadConversationsByDomainNameAction"]),
    },

    async created() {
        await this.loadConversationsByDomainNameAction(this.inventoryItem.name);
    },
};
</script>

<style></style>
