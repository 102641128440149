<template>
  <v-menu
      v-if="domainName"
      v-model="menu"
      :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          tile
          v-on="on"
          x-small
          class="mb-1"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>{{ domainName }}</v-card-title>

      <v-card-text>
        <div>
          <v-text-field
              v-model="sibling"
              label="New Sibling"
              required
          />
          <v-select
              v-model="sibling"
              :items="siblings"
              label="Sibling"
          />
        </div>
        <v-text-field
            v-model="phone"
            label="Phone"
        />
        <v-text-field
            v-model="email"
            label="Email"
        />
        <v-text-field
            v-model="contactName"
            label="Name"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="blue darken-1"
            text
            @click="list"
        >
          Send
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="menu = false"
        >
          Close
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="google"
        >
          Google
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="apollo"
        >
          Apollo
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: ["domainName"],
  data: () => ({
    menu: false,
    phone: '',
    email: '',
    contactName: '',
    sibling: '',
    siblings: [],
  }),

  computed: {
    ...mapGetters("domains", ["getDomainByNameFromAPI"]),

    ...mapGetters({
      conversations: "conversations/getConversationsByDomainName",
    }),


  },

  methods: {
    ...mapActions("conversations", ["newConversationAction"]),
    ...mapActions("apollo", ["findApolloLeads"]),


    list() {
      const conversation = {
        lead: {
          domain: this.domainName,
          sibling: this.sibling,
          phone: this.phone,
          email: this.email,
          contactName: this.contactName,
        },
        stage: "pending sale",
      }

      this.newConversationAction(conversation);
      this.menu = false;
    },

    google() {
      let target = `https://www.google.com/search?q=${this.domainName.substr(0, this.domainName.indexOf('.'))}`;
      window.open(target, "_blank", "noreferrer");
    },

    async apollo() {
      const result = await this.findApolloLeads(this.domainName)
      console.log(result.data);
      this.menu = false;
    },
  },
  async mounted() {
    if (!this.domain) {
      await this.$store.dispatch(
          "domains/loadDomainByNameAction",
      );
    }


    const {data: domain} = await this.getDomainByNameFromAPI(this.domainName);
    if (domain) {
      this.siblings = domain.siblings
          ? domain.siblings.map((sibling) => sibling.name)
          : [];
    }


  }
};
</script>

<style></style>
